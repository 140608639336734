<template>
  <div>
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>
        <div class="search-wrappper">
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              placeholder="Search here..."
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <my-button
        type="orange"
        @click="addModal = true; editData=false;"
      >
        <fa-icon icon="edit" class="my-icon" />&nbsp; BUAT KELAS BARU
      </my-button>
    </div>
    <br />
    <br />

    <label>Filter Jenjang</label><br />
    <b-input-group size="sm">
      <b-form-select
        v-model="level_id"
        :options="levelOptionList"
        @change="changeLevel"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <label class="mt-4" v-if="majorOptionList.length > 1">Filter Jurusan</label><br />
    <b-input-group size="sm" v-if="majorOptionList.length > 1">
      <b-form-select
        v-model="major_id"
        :options="majorOptionList"
        @change="changeMajor"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleEdit(row.item.id)"
        >
          <fa-icon icon="pencil-alt" class="my-icon" />&nbsp;Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-danger"
          @click="handleDelete(row.item)"
        >
          <fa-icon icon="minus-circle" class="my-icon" />&nbsp;Delete
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-primary"
          @click="handleGo(row.item)"
        >
          <fa-icon icon="arrow-right" class="my-icon" />&nbsp;Go
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-modal
      v-model="addModal"
      id="addModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <modal-cross @close="addModal = false"></modal-cross>
      <div class="p-4">
        <h3 class="txt-center color2">{{editData ? "Edit Kelas" : "Buat Kelas Baru"}}</h3>
        <br />
        <label>Pilih Jenjang</label>
        <br />
        <b-form-select
          v-model="inputData.level_id"
          :options="levelOption"
          required
          :disabled="editData"
        ></b-form-select>
        <label class="mt-4" v-if="majorOption.length > 0">Pilih Jurusan</label>
        <b-form-select
          v-model="inputData.major_id"
          :options="majorOption"
          :disabled="editData"
          class="mt-2"
           v-if="majorOption.length > 0"
        ></b-form-select>
        <br />
        <br />
        <br />
        <label>Nama Kelas</label>
        <br />
        <b-form-input
          placeholder="Nama .. "
          v-model="inputData.name"
        ></b-form-input>
        <br />
        <label>Deskripsi</label>
        <b-form-input
          placeholder="Deskripsi Singkat .. "
          v-model="inputData.description"
        ></b-form-input>
        <br />
        <div class="justify-end">
          <my-button
            type="blue"
            disabled
            v-show="showLoading"
          >
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </my-button>
          <my-button
            type="blue"
            v-show="!showLoading"
            @click="handleSave"
          >
            SIMPAN
          </my-button>
        </div>
        <br />
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      editData: false,
      level_id: null,
      levelOptionList: [],
      major_id: null,
      majorOptionList: [],
      isBusy: false,
      items: [],
      fields: [
        {
          key: "no",
          label: "No"
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "major",
          label: "Jurusan",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "level",
          label: "Jenjang",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "actions",
          label: "Action"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      addModal: false,
      inputData: {
        name: "",
        description: "",
        level_id: null,
        major_id: null,
        id: null
      },
      showLoading: false,
      levelOption: [],
      majorOption: []
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.totalRows = this.items.length;
    this.levelList();
    this.majorList();
    if(this.$route.query.level) {
      this.level_id = this.$route.query.level;
    } 
    if(this.$route.query.major) {
      this.major_id = this.$route.query.major;
    } 
    this.classList();
  },

  watch: {
    "inputData.level_id": function(newVal) {
      if(newVal) {
        this.inputData.major_id = null;
        this.getMajorList({params: {level: newVal}})
          .then(response => {
            var res = response;
            this.majorOption = [];
            for (var i = 0; i < res.length; i++) {
              var datamajor = {
                value: res[i].id,
                text: res[i].name
              };
              this.majorOption.push(datamajor);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } 
    },
    $route: function(newVal) {
      this.level_id = this.$route.query.level ? this.$route.query.level : null;
      this.major_id = this.$route.query.major ? this.$route.query.major : null;
      this.majorList();
      this.classList();
    }
  },

  methods: {
    ...mapActions({
      getLevelList: types.GET_LEVEL_LIST,
      getClassList: types.GET_CLASS_LIST,
      getMajorList: types.GET_MAJOR_LIST,
      setNewClass: types.SET_NEW_CLASS,
      getClassDetail: types.GET_CLASS_DETAIL,
      updateClass: types.UPDATE_CLASS,
      deleteClass: types.DELETE_CLASS
    }),
    changeMajor() {
      if(this.major_id) {
        this.$router.push("/master-data/class?level="+this.level_id+"&major="+this.major_id);
      } else {
        this.$router.push("/master-data/class?level="+this.level_id);
      }
    },
    changeLevel() {
      if(this.level_id) {
        this.$router.push("/master-data/class?level="+this.level_id);
      } else {
        this.$router.push("/master-data/class");
      }
    },
    levelList() {
      this.getLevelList()
        .then(response => {
          var res = response;
          this.levelOption = [];
          this.levelOptionList = [{value: null, text: "All"}];
          for (var i = 0; i < res.length; i++) {
            var dataLevel = {
              value: res[i].id,
              text: res[i].name
            };
            this.levelOption.push(dataLevel);
            this.levelOptionList.push(dataLevel);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    majorList() {
      var level_id = this.$route.query.level ? this.$route.query.level : null;
      if(level_id) {
        this.getMajorList({params: {level: level_id}})
          .then(response => {
            var res = response;
            this.majorOptionList = [{value: null, text: "All"}];
            for (var i = 0; i < res.length; i++) {
              var datamajor = {
                value: res[i].id,
                text: res[i].name
              };
              this.majorOptionList.push(datamajor);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.majorOptionList = [{value: null, text: "All"}];
      }
    },
    classList() {
      this.level_id = this.$route.query.level ? this.$route.query.level : null;
      this.major_id = this.$route.query.major ? this.$route.query.major : null;
      this.isBusy = true;
      this.getClassList({params: {level: this.level_id, major: this.major_id}})
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              name: res[i].name,
              level: res[i].level,
              level_id: res[i].level_id,
              major: res[i].major,
              major_id: res[i].major_id,
              description: res[i].description
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEdit(no) {
      this.editData = true;
      this.addModal = true;
      this.getClassDetail(no)
        .then(response => {
          var res = response;
          this.inputData.name = res.name;
          this.inputData.description = res.description;
          this.inputData.id = res.id;
          this.inputData.level_id = res.level_id;
          this.inputData.major_id = res.major_id;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    handleSave() {
      if(this.editData) {
        this.editClass();
      } else {
        this.addClass();
      }
    },
    handleGo(item) {
      var router = "/master-data/subject?level="+item.level_id+"&class="+item.id;
      if(item.major_id) {
        this.$router.push(router+"&major="+item.major_id);
      } else {
        this.$router.push(router);
      }
    },
    handleDelete(index) {
      this.$bvModal.msgBoxConfirm('Delete this data?', {
        title: 'Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if(value) {
            this.deleteClass({id:index.id})
              .then(response => {
                this.classList();
                this.showMessageBox("Deleted", "Success", "success");
              })
              .catch(error => {
                this.showMessageBox(error, "Failed", "success");
              });
          }
        })
        .catch(err => {
          this.showMessageBox(err, "Failed", "success");
        });
    },
    closeModal() {
      this.$bvModal.hide("modal-confirm");
    },
    addClass() {
      this.showLoading = true;
      this.setNewClass(this.inputData)
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.addModal = false;
          this.showLoading = false;
          this.inputData = {
            name: "",
            description: "",
            level_id: null,
            major_id: null
          };
          this.classList();
        })
        .catch(error => {
          this.showLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    editClass() {
      this.showLoading = true;
      this.updateClass(this.inputData)
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.addModal = false;
          this.showLoading = false;
          this.classList();
        })
        .catch(error => {
          this.showLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
          this.temp = value;
        })
        .catch(err => {
          this.temp = err;
        });
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}
</style>